import {DataStorageKey, membersAppDefId, wishlistAppDefId, wishlistSection} from '../constants';
import {FetchError} from '@wix/wixstores-client-core/dist/src/http/FetchError';
import {BulkInstallResponse} from '../types';
import {errorReporter} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {SUBSCRIPTION_APP_DEFINITION_ID} from '@wix/wixstores-client-core/dist/src/constants';
import {
  addApplications,
  isMembersAreaAppInstalled,
  isMembersAreaInstalled,
  MA_APP_IDS,
  registerMembersAreaApps,
  removeApplications,
} from '@wix/members-area-integration-kit';
import {DataStorage} from './DataStorage';
import {EditorSDK} from '@wix/platform-editor-sdk';
import {wishlistInstallationStage} from '@wix/bi-logger-ec-sf/v2';
import {Logger as WebLoggerType} from '@wix/web-bi-logger/dist/src/types';
import {WIX_ECOM, WIX_NEW_STORES} from '@wix/app-definition-ids';

export class DependantApps {
  private readonly dataStorage: DataStorage;

  constructor(
    private readonly sdk: EditorSDK,
    private readonly instance: string,
    metaSiteId: string,
    private readonly webBiLogger: WebLoggerType
  ) {
    this.dataStorage = new DataStorage(metaSiteId);
  }

  private getMembersApi(): Promise<any> {
    return this.sdk.application.getPublicAPI('', {appDefinitionId: membersAppDefId});
  }

  public isAppInstalled(appDefinitionId: string): Promise<boolean> {
    return this.sdk.tpa.isApplicationInstalled('', {
      appDefinitionId,
    });
  }

  public async installNewStoresAppIfNeeded() {
    const isNewStoresInstalled = await this.sdk.application.isApplicationInstalled('', {
      appDefinitionId: WIX_NEW_STORES,
    });
    if (isNewStoresInstalled) {
      return Promise.resolve();
    }
    return this.sdk.application.install('', {
      appDefinitionId: WIX_NEW_STORES,
      isSilent: true,
      originInfo: {'': ''},
    });
  }

  public addCheckoutIfNeeded() {
    return this.sdk.tpa.isAppSectionInstalled('', {sectionId: 'checkout'}).then((isInstalled) => {
      if (!isInstalled) {
        return this.sdk.tpa.add.component('', {
          appDefinitionId: WIX_ECOM,
          componentType: 'PAGE',
          page: {
            pageId: 'checkout',
            shouldNavigate: false,
          },
        });
      }
    });
  }

  public installWishlistTPA(): Promise<void> {
    return fetch('/_api/site-apps/v1/site-apps/bulk-install', {
      method: 'post',
      body: JSON.stringify({
        apps: [{id: wishlistAppDefId}],
      }),
      headers: {
        Authorization: this.instance,
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.webBiLogger.report(
            wishlistInstallationStage({stage: `requestId${response.headers.get('x-wix-request-id')}`})
          );
          console.error(response);
          errorReporter(new FetchError('bulk install failed', {response}));
          throw new FetchError('bulk install failed', {response});
        }
        return response.json();
      })
      .then((data: BulkInstallResponse) => {
        if (!data.installedApps[wishlistAppDefId].instanceId) {
          throw new Error('bulk install failed - no instanceId was returned');
        }
      });
  }

  public async tryInstallWishlist(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.webBiLogger.report(wishlistInstallationStage({stage: 'tryInstallWishlist-init'}));
    await this.installWishlistTPA();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.webBiLogger.report(
      wishlistInstallationStage({stage: 'tryInstallWishlist-after-create-installWishlistTPAPromise'})
    );
    await addApplications([MA_APP_IDS.MY_WISHLIST]);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.webBiLogger.report(
      wishlistInstallationStage({stage: 'tryInstallWishlist-after-create-installWishlistSectionInMembersPromise'})
    );
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.webBiLogger.report(wishlistInstallationStage({stage: 'tryInstallWishlist-after-promiseAll'}));
  }

  public async installWishlistPageInMembersArea(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.webBiLogger.report(wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-init'}));
    const [isMembersInstalled, isMyWishlistInstalled] = await Promise.all([
      isMembersAreaInstalled(),
      isMembersAreaAppInstalled(MA_APP_IDS.MY_WISHLIST),
    ]);
    if (isMembersInstalled && !isMyWishlistInstalled) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.webBiLogger.report(wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-inside-if'}));
      const installWishlistTpaPromise = this.installWishlistTPA();

      const installWishlistSectionInMembersPromise = addApplications([MA_APP_IDS.MY_WISHLIST]);
      await Promise.all([installWishlistSectionInMembersPromise, installWishlistTpaPromise]);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.webBiLogger.report(wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-after-promise-all'}));
      await this.sdk.document.save().catch(() => {
        //
      });
    }
  }

  public async uninstallWishlistPageInMembersArea(): Promise<void> {
    const [isMembersInstalled, isMyWishlistInstalled] = await Promise.all([
      isMembersAreaInstalled(),
      isMembersAreaAppInstalled(MA_APP_IDS.MY_WISHLIST),
    ]);
    if (isMembersInstalled && isMyWishlistInstalled) {
      await removeApplications([MA_APP_IDS.MY_WISHLIST]);
      await this.sdk.document.save().catch(() => {
        //
      });
    }
  }

  public async navigateToWishlist(): Promise<void> {
    const membersAreaAPI = await this.getMembersApi();
    return membersAreaAPI.navigateToSection(wishlistSection);
  }

  public async installMySubscriptionsPageInMembersAreaIfNeeded(options?: {biData?: {origin?: string}}) {
    const isSubscriptionsAppInstalled = await this.isAppInstalled(SUBSCRIPTION_APP_DEFINITION_ID);
    if (!isSubscriptionsAppInstalled) {
      return;
    }

    await registerMembersAreaApps(
      [MA_APP_IDS.MY_SUBSCRIPTIONS],
      [
        {
          id: MA_APP_IDS.MY_SUBSCRIPTIONS,
          options: {
            shouldInstallInitially: false,
          },
        },
      ]
    );

    const membersAreaInstalled = await isMembersAreaInstalled();
    if (membersAreaInstalled) {
      const mySubscriptionsAlreadyInstalled = await this.dataStorage.getData(
        DataStorageKey.MySubscriptionsAlreadyInstalled
      );

      if (mySubscriptionsAlreadyInstalled) {
        return;
      }

      await addApplications([MA_APP_IDS.MY_SUBSCRIPTIONS], options);
      await this.dataStorage.setData(DataStorageKey.MySubscriptionsAlreadyInstalled, true);
    }
  }
}
