import {WIX_ECOM} from '@wix/app-definition-ids';
import {PageMap} from '@wix/wixstores-client-core/dist/src/constants';
import {EditorSDK} from '@wix/platform-editor-sdk';

export async function getEcomPages(sdk: EditorSDK) {
  const ecomApplicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  return allSitePages.filter((page) => page.tpaApplicationId === ecomApplicationId);
}

export async function getShopPagesTpaPageId(sdk: EditorSDK) {
  const ecomPages = await getEcomPages(sdk);
  const shopPages = ecomPages.filter((page) => page.tpaPageId?.startsWith(PageMap.GALLERY));
  return shopPages.map((x) => x.tpaPageId);
}

export async function checkIfPageExists(sdk: EditorSDK, page: PageMap) {
  const ecomPagesData = await getEcomPages(sdk);
  return !!ecomPagesData.find((p) => p.tpaPageId === page);
}

export async function hasCategoryPageOnSite(sdk: EditorSDK) {
  const ecomPages = await getEcomPages(sdk);
  return !!ecomPages.find((page) => page.tpaPageId?.startsWith(PageMap.CATEGORY));
}
