import {maybeInstallMembersArea} from '@wix/members-area-integration-kit';
import {doTransaction} from '../../transaction';
import {DependantApps} from '../../services/DependantApps';
import {EditorSDK} from '@wix/platform-editor-sdk';
import {closeProgressBar, showInstallProgressBar} from './wishlistProgressBar';
import {showInstallNotification} from './wishlistNotification';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {wishlistInstallationStage} from '@wix/bi-logger-ec-sf/v2';

export const enum InstallationOrigin {
  ProductPageSettings = 'product-page-settings',
  Plugins = 'plugins',
}

type CommonParams<T extends InstallationOrigin> = {origin: T; installMembersArea?: boolean};
type InstallWishlistParams<T extends InstallationOrigin> = T extends InstallationOrigin.Plugins
  ? CommonParams<T> & {openerCompRef?: string}
  : CommonParams<T> & {openerCompRef: string};

export function createWishlistApi({
  sdk,
  dependantApps,
  t,
  webBiLogger,
}: {
  sdk: EditorSDK;
  dependantApps: DependantApps;
  t: (keyToTranslate: string) => string;
  webBiLogger: WebBiLogger;
}) {
  return {
    installWishlist: <T extends InstallationOrigin>({
      origin,
      openerCompRef,
      installMembersArea,
    }: InstallWishlistParams<T>) => {
      return doTransaction(sdk, async () => {
        const biMethodName = installMembersArea ? 'installMembersAreaAndWishlistPage' : 'installWishlist';
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        webBiLogger.report(wishlistInstallationStage({stage: `${biMethodName}-init`}));
        const progressBarPromise = showInstallProgressBar(sdk, t, installMembersArea);
        if (installMembersArea) {
          await maybeInstallMembersArea();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          webBiLogger.report(wishlistInstallationStage({stage: `${biMethodName}-after-members-install`}));
        }
        await dependantApps.tryInstallWishlist();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        webBiLogger.report(wishlistInstallationStage({stage: `${biMethodName}-after-wishlist-install`}));

        await closeProgressBar(progressBarPromise);

        if (origin === InstallationOrigin.ProductPageSettings) {
          await enableProductPageWishlist(sdk, openerCompRef);
        }
        void showInstallNotification({sdk, dependantApps, t, installMembersArea, webBiLogger}).catch(() => undefined);
      });
    },

    uninstallWishlist: () => {
      return doTransaction(sdk, () => dependantApps.uninstallWishlistPageInMembersArea());
    },
  };
}

function enableProductPageWishlist(sdk: EditorSDK, productPageCompRef: string) {
  return sdk.tpa.setStyleParams('', {
    compRef: {id: productPageCompRef, type: 'DESKTOP'},
    styleParams: [{type: 'boolean', key: 'productPage_wishlistEnabled', param: {value: true}}],
  });
}
