import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {WidgetPluginInterfaces} from '@wix/widget-plugins-interfaces';
import {StoresWidgetID, SlotsMap} from '@wix/wixstores-client-core';

export const configureSlots = (appManifestBuilder: AppManifestBuilder): AppManifestBuilder =>
  appManifestBuilder
    .configureWidget(StoresWidgetID.GRID_GALLERY, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.SLIDER_GALLERY, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.WISHLIST_PAGE, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.GALLERY_PAGE, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.CATEGORY_PAGE, (widgetBuilder) => {
      galleryWidgetBuilder(widgetBuilder);
    })
    .configureWidget(StoresWidgetID.PRODUCT_PAGE, (widgetBuilder) => {
      widgetBuilder.slots().set({
        [SlotsMap.ProductPageDetails]: {
          displayName: 'Product Page Details Slot 1',
          interfaces: [WidgetPluginInterfaces.RATINGS_SUMMARY],
        },
        [SlotsMap.ProductPageBottom]: {
          displayName: 'Product Page Bottom Slot',
          interfaces: [WidgetPluginInterfaces.REVIEWS],
        },
        [SlotsMap.ProductPageTop]: {
          displayName: 'Product Page Top Slot',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
        [SlotsMap.ProductPageAdditionalButtons]: {
          displayName: 'Product Page Additional Buttons Slot',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
        [SlotsMap.ProductPageDetails2]: {
          displayName: 'Product Page Details Slot 2',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
        [SlotsMap.ProductPageDetails3]: {
          displayName: 'Product Page Details Slot 3',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
        [SlotsMap.ProductPageDetails4]: {
          displayName: 'Product Page Details Slot 4',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
        [SlotsMap.ProductPageDetails5]: {
          displayName: 'Product Page Details Slot 5',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
        [SlotsMap.ProductPageDetails6]: {
          displayName: 'Product Page Details Slot 6',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
        [SlotsMap.ProductPageDetails7]: {
          displayName: 'Product Page Details Slot 7',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
        [SlotsMap.ProductPageDetails8]: {
          displayName: 'Product Page Details Slot 8',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
        [SlotsMap.ProductPageDetails9]: {
          displayName: 'Product Page Details Slot 9',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
        [SlotsMap.ProductPageMedia1]: {
          displayName: 'Product Page Media Slot 1',
          interfaces: [WidgetPluginInterfaces.PRODUCT],
        },
      });
    });

const galleryWidgetBuilder = (widgetBuilder) => {
  widgetBuilder.slots().set({
    [SlotsMap.ProductGalleryDetails]: {
      displayName: 'Product Gallery Details Slot 1',
      interfaces: [WidgetPluginInterfaces.RATINGS_SUMMARY_OOI_LIST],
    },
    [SlotsMap.GalleryProductsTop]: {
      displayName: 'Gallery Products Top Slot',
      interfaces: [WidgetPluginInterfaces.CATEGORY],
    },
    [SlotsMap.GalleryProductsBottom]: {
      displayName: 'Gallery Products Bottom Slot',
      interfaces: [WidgetPluginInterfaces.CATEGORY],
    },
    [SlotsMap.CategoryPageListTop]: {
      displayName: 'Category Page List Top Slot',
      interfaces: [WidgetPluginInterfaces.CATEGORY],
    },
    [SlotsMap.GalleryFiltersTop]: {
      displayName: 'Gallery Filters Top Slot',
      interfaces: [WidgetPluginInterfaces.CATEGORY],
    },
    [SlotsMap.GalleryFiltersBottom]: {
      displayName: 'Gallery Filters Bottom Slot',
      interfaces: [WidgetPluginInterfaces.CATEGORY],
    },
    [SlotsMap.CategoryPageHeroTop]: {
      displayName: 'Category Page Hero Top Slot',
      interfaces: [WidgetPluginInterfaces.CATEGORY],
    },
    [SlotsMap.CategoryPageHeroBottom]: {
      displayName: 'Category Page Hero Bottom Slot',
      interfaces: [WidgetPluginInterfaces.CATEGORY],
    },
  });
};
